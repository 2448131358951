import styled, { css } from "styled-components";
import { media } from "../Theme/default-theme";

const PlaceholderStyle = css`
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 325;
  color: #382e2b;
  opacity: 1;
`;

export const SearchOption = styled.div`
  position: relative;
  background: #fff;
  color: inherit;
  cursor: pointer;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;

  &:hover {
    background: #deebff;
  }
`;

export const SearchModal = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: absolute;
  z-index: 9;
  width: 100%;
  top: 42px;
  left: 0;
  background: #fff;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 0px 1px, rgb(0 0 0 / 10%) 0px 4px 11px;
  margin: 8px 0;
  box-sizing: border-box;
  max-height: 300px;
  overflow-y: auto;
  padding: 4px 0;
  min-height: 42px;
`;

export const StyledInput = styled.input`
  width: 100%;
  margin-bottom: 12px;
  padding: 8px 0 8px 12px;
  background: #fff;
  border: 1px solid rgba(56, 46, 43, 0.4);
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 325;
  color: #382e2b;
  border-radius: 0;
  cursor: default;

  ::placeholder {
    ${PlaceholderStyle}
  }

  :disabled {
    color: #382e2b;
    background-color: #edeaea;
  }

  :focus {
    outline: none;
  }

  :focus:invalid {
    border: 1px solid #ff5f00;
  }

  :focus:valid {
    border: 1px solid rgba(56, 46, 43, 0.4);
  }

  ${({ is_touched }) =>
    is_touched &&
    css`
      :required:invalid {
        border: 1px solid red;
      }
      :required:valid {
        border: 1px solid rgba(56, 46, 43, 0.4);
      }
    `}

  ${media.tablet} {
    margin-bottom: 15px;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
`;
