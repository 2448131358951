import styled, { css } from "styled-components";
import Link from "gatsby-link";
import Image from "components/Image";
import LearnMoreBtn from "components/LearnMoreBtn";
import Input from "components/Input";
import { media } from "../Theme/default-theme";

const defaultTextStyles = css`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-size: 14px;
  text-decoration: none;
  width: fit-content;
  align-self: flex-start;
`;

const ButtonStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  line-height: 125%;
  font-weight: 325;
  text-align: center;
  margin: 2em 0;
`;

const defaultLinkStyles = css`
  padding: 0.25em 0;
  margin: 0 0 0.5em;
  border-bottom: 1px solid transparent;
  word-break: break-word;

  :hover {
    border-bottom: 1px solid;
  }
`;

export const FormTextInput = styled(Input)`
  width: 100%;
  max-width: 430px;
  background: #fff;
  margin: 0;
  cursor: auto;
`;

export const FormSubmitButton = styled(LearnMoreBtn)`
  ${ButtonStyle}
  width: 100%;
  padding: 1em;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  align-items: center;
`;

export const InternalLink = styled(Link)`
  ${defaultTextStyles}
  ${defaultLinkStyles}
  font-weight: 350;
`;

export const Copyright = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2.5px;
  margin: 0 0 5px;
`;

export const Legal = styled.div`
  p,
  ${InternalLink} {
    ${defaultTextStyles}
    font-weight: 400;
    font-size: 8px;
    margin: 0;
    padding: 0;
  }

  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 3em auto 0;
  width: auto;
  max-width: 95%;
`;

export const IconImage = styled(Image)`
  width: 100%;
  height: 100%;
  aspect-ratio: 3.5;
  max-width: 144px;
`;

export const ExternalLink = styled.a`
  ${defaultTextStyles}
  ${defaultLinkStyles}
  font-weight: 350;
`;

export const SectionLabel = styled.h3`
  ${defaultTextStyles}
  white-space: nowrap;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 11px;
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  row-gap: 1.3em;
  padding: 0;
`;

export const SubscribeTitle = styled.h2`
  line-height: 125%;
  font-style: normal;
  font-weight: 425;
  font-family: Montserrat, sans-serif;
  color: #382e2b;
  text-align: center;
  font-size: clamp(0.6875rem, 0.6375rem + 0.1665vw, 0.8125rem);
  margin: 0;
  text-transform: uppercase;
`;

export const Grid = styled.div`
  display: grid;
  width: 100%;
  gap: 1em;
`;

export const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 95%;
  margin: 0 auto;
  width: auto;
  max-width: 95%;
`;

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  background: #d9d9d9;
  padding: 4em 0;

  .link-section-container {
    grid-template-columns: repeat(2, minmax(100px, 1fr));

    .links-section {
      justify-content: flex-start;
    }
  }

  .form-subscribe-container {
    width: 100%;
    padding: 4em 0 2em;
  }

  .app-links-container {
    gap: 0.5em;
  }

  ${media.tablet} {
    ${Content} {
      margin: 0 2.5em;
    }

    ${Legal} {
      margin: 2.5em;
    }

    .link-section-container {
      grid-template-columns: repeat(4, 1fr);
      max-width: fit-content;
      align-self: flex-start;
    }

    .form-subscribe-container {
      align-self: flex-start;

      button {
        max-width: 201px;
      }
    }

    .app-links-container {
      gap: 0;
      flex-direction: column;
      align-self: flex-start;
      padding: 3em 0 0;

      .icon-button-link {
        max-width: 100px;
        max-height: 100%;
      }
    }
  }

  ${media.laptop} {
    ${Content} {
      flex-direction: row;
      justify-content: flex-start;

      .left-col {
        flex: 1;
        max-width: fit-content;
        align-self: flex-start;
      }

      .right-col {
        flex: 0 1 10%;
        align-self: flex-start;

        .app-links-container {
          padding: 0;

          .icon-button-link {
            max-width: 93px;
            max-height: 100%;
          }
        }
      }
    }
  }
`;
