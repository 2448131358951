import sitesparkDefaultTheme from "@racepointenergy/gatsby-theme-sitespark/src/components/Theme/default-theme";
import { keyframes } from "styled-components";

export const size = {
  mobile: 480,
  tablet: 768,
  laptop: 1400,
  desktop: 1681,
};

const translateLeftToRight = keyframes`
  from {
    transform: translateX(-50%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const translateTopToBottom = keyframes`
  from {
    transform: translateY(-50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const translateFullTopToBottom = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const animations = {
  translateLeftToRight,
  translateTopToBottom,
  translateFullTopToBottom,
};

export const media = {
  mobile: `@media (min-width: ${size.mobile}px)`,
  tablet: `@media (min-width: ${size.tablet}px)`,
  laptop: `@media (min-width: ${size.laptop}px)`,
  desktop: `@media (min-width: ${size.desktop}px)`,
};

const defaultTheme = {
  colors: sitesparkDefaultTheme.colors,
  size,
  media,
  animations,
  fontFamily: "Montserrat",
};

export default defaultTheme;
